import { createError } from '#app';
import { NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';
import { useUpdateSupplierInfo } from 'composables/useUpdateSupplierInfo';
import { useUserStore } from 'store/user.store';

export default defineNuxtRouteMiddleware(async () => {
  await useUpdateSupplierInfo();
  const userStore = useUserStore();

  if (!userStore.isSupplierAdmin) {
    throw createError({
      statusCode: NOT_FOUND_ERROR_CODE,
    });
  }
});
